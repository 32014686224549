<template>
    <div class="tag">
        <h3>{{ t("about.brand") }}</h3>
        <p>{{t('about.m-2024-b1')}}</p>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/doo-property-logo.png" class="lesspay"/>
        <p>{{t('about.m-2024-b2')}}</p>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/doo-consulting-logo.png" class="lesspay"/>
        <p>{{t('about.m-2024-b3')}}</p>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/doo-cloud-logo.png" class="lesspay"/>
        <p>{{t('about.m-2024-b4')}}</p>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/doo-digital-logo.png" class="lesspay"/>
        <p>{{t('about.m-2024-b5')}}</p>
        <p>{{t('about.m-2024-b6')}}</p>
        <p>{{t('about.m-2024-b7')}}</p>
        <p>{{t('about.m-2024-b8')}}</p>
    </div>
    <div class="tag">
        <h3>{{ t("about.safety") }}</h3>
        <p>{{t('about.m-2024-s1')}}</p>
        <p>{{t('about.m-2024-s2')}}</p>
        <p>{{t('about.m-2024-s3')}}</p>
        <p>{{t('about.m-2024-s4')}}</p>
        <p>{{t('about.m-2024-s5')}}</p>
        <p>{{t('about.m-2024-s6')}}</p>
        <p>{{t('about.m-2024-s7')}}</p>
        <p>{{t('about.m-2024-s8')}}</p>
        <p>{{t('about.m-2024-s9')}}</p>
        <p>{{t('about.m-2024-s10')}}</p>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const imageUrl2 = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/";

const { t } = useI18n();
</script>