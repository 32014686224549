<template>
    <section id="goals">
      <div class="container">
        <h2>{{ t("aufc.goals") }}</h2>
        <p>{{ t("aufc.goals-desc") }}</p>

        <div class="wrapper">
          <div class="ambition">
            <div class="content">
              <h3>{{ t("aufc.ambition") }}</h3>
              <p>{{ t("aufc.ambition-desc-1") }}</p>
            </div>
          </div>
          <div class="loyalty-img content-hover">
            <img :src="`${ImageUrl}loyalty.png`" class="web"/>
            <img :src="`${ImageUrl}goals_2_mobile.png`" class="mobile"/>
            <div>
              <p>{{ t("aufc.cooper-stadium") }}</p>
            </div>
          </div>
          <div class="ambition-img content-hover">
            <img :src="`${ImageUrl}ambition.png`" class="web"/>
            <img :src="`${ImageUrl}goals_1_mobile.png`" class="mobile"/>
            <div>
              <p>{{ t("aufc.display") }}</p>
            </div>
          </div>
          <img :src="`${ImageUrl}goals_logo.png`" class="web logo"/>
          <div class="loyalty">
            <div class="content-wrapper">
              <div class="content">
                <h3>{{ t("aufc.loyalty") }}</h3>
                <p>{{ t("aufc.loyalty-desc-1") }}</p>
              </div>
            </div>
          </div>
          <div class="transparency">
            <div class="content-wrapper">
              <div class="content">
                <h3>{{ t("aufc.transparency") }}</h3>
                <p>{{ t("aufc.transparency-desc-1") }}</p>
              </div>
            </div>
          </div>
          <div class="goals_3 content-hover">
            <img :src="`${ImageUrl}goals_3.png`" class="web"/>
            <img :src="`${ImageUrl}goals_3_mobile.png`" class="mobile"/>
            <div>
              <p>{{ t("aufc.coin-toss") }}</p>
            </div>
          </div>
          <div class="goals_5 content-hover">
            <img :src="`${ImageUrl}goals_5.png`" class="web"/>
            <img :src="`${ImageUrl}goals_5_mobile.png`" class="mobile"/>
            <div>
              <p>{{ t("aufc.dg-logo") }}</p>
            </div>
          </div>
          <div class="goals_4 content-hover">
            <img :src="`${ImageUrl}goals_4.png`" class="web"/>
            <img :src="`${ImageUrl}goals_4_mobile.png`" class="mobile"/>
            <div>
              <p>{{ t("aufc.connections") }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
<script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { useI18n } from 'vue-i18n';

  const ImageUrl = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/"
  
  const { t } = useI18n();

  const socialMedia = ["instagram", "facebook", "linkedin", "youtube"]

  // onMounted(() => {
  //   handleWindowChangeSize();
  //   window.addEventListener("resize", handleWindowChangeSize);
  // });
  
  // onUnmounted(() => {
  //   window.removeEventListener("resize", handleWindowChangeSize);
  // });
</script>

<style lang="less">
  @import '@/less/mediaQuery.less';
  @tablet-screen: ~"only screen and (max-width:1430px) and (min-width:834px)";
  #goals {
    padding-top: 180px;
    
    @media @mobile-screen {
      padding-top:80px;
    }
    h2 {
      font-size: 76px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      color: #3e3a39;
      margin-bottom:24px;
      text-align: center;
      @media @mobile-screen {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: #3e3a39;
        margin-bottom:16px;
      }
    }
    .container {
      > p {
        font-size: 19px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        max-width:580px;
        margin:0 auto 80px;
        @media @mobile-screen {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #3e3a39;
          margin-bottom:40px;
        }
      }
    }
    .wrapper {
      gap: 24px;
      @media only screen and (min-width:834px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        -grid-auto-rows: minmax(100px, auto);
        gap: 24px;
        align-items: end;
      }
      @media @tablet-screen {
        gap: 1.7vw;
      }
      @media @mobile-screen {
        display: flex;
        flex-direction: column;
        gap:0;
        align-items: center;
        > * {
          margin-bottom:16px;
        }
      }
      .content {
        background:#e03323;
        padding:24px;
        max-width:452px;
        min-height:300px;
        @media @tablet-screen {
          padding: 1.7vw;
          max-width: 32vw;
          min-height: 21.3vw;
        }
        @media @mobile-screen {
          max-width: 600px;
          margin: 0 auto;
          min-height:initial;
          padding:16px;
        }
        
        h3 {
          font-size: 32px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          text-transform: uppercase;
          margin-bottom:24px;
          @media @tablet-screen {
            font-size: 2.3vw;
            margin-bottom: 1.7vw;
          }
          @media @mobile-screen {
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            margin-bottom:20px;
          }
        }
      }
      p {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        margin-bottom:16px;
        position:relative;
        padding-left:24px;
        @media @tablet-screen {
          font-size: 1.15vw;
          padding-left: 1.7vw;
        }
        @media @mobile-screen {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          padding-left:20px;
          margin-bottom:12px;
        }
        &:last-child{
          margin-bottom:0;
        }
        &:before {
          content:'';
          position:absolute;
          left:0;
          top: 7px;
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-left: 16px solid #fff;
          border-bottom: 8px solid transparent;
          @media @tablet-screen {
            top: 0.2vw;
            border-top: 0.6vw solid transparent;
            border-left: 1.2vw solid #fff;
            border-bottom: 0.6vw solid transparent;
          }
          @media @mobile-screen {
            top: 4px;
            border-top: 6px solid transparent;
            border-left: 12px solid #fff;
            border-bottom: 6px solid transparent;
          }
        }
        
      }
    }
    img {
      max-width: 100%;
      @media @mobile-screen {
        max-width: 600px !important;
        width: 100%;
      }
    }
    .ambition {
      @media @mobile-screen {
        order: 1;
      }
    }
    .ambition-img {
      grid-row: span 2;
      height: 100%;
      @media @mobile-screen {
        order: 2;
      }
      img {
        object-fit: cover;
        height: 100%;
      }
    }
    .loyalty {
      @media @mobile-screen {
        order: 3;
      }
    }
    .loyalty-img {
      grid-row: span 2;
      grid-column: span 2;
      object-fit: cover;
      height: 100%;
      @media @tablet-screen {
        min-height:32.8vw;
        img {
          min-height:32.8vw;
        }
      }
      @media @mobile-screen {
        order: 4;
      }
      img {
        height:100%;
        object-fit: cover;
      }
    }
    .logo {
      max-width:391px;
      position: relative;
      top: -146px;
      margin: 0 auto;
      @media @tablet-screen {
        max-width: 27.6vw;
        top: -10.45vw;
      }
    }
    .transparency{
      @media @mobile-screen {
        order: 6;
      }
      .content {
        min-height: 341px;
        @media @tablet-screen {
          min-height: 24.5vw;
        }
        @media @mobile-screen {
          min-height: initial;
        }
      }
    }
    .transparency,
    .goals_3,
    .goals_4 {
      position: relative;
      top: -125px;
      @media @tablet-screen {
        top: -8.7vw;
      }
      @media @mobile-screen {
        top:initial;
      }
    }
    .goals_3 {
      height: 100%;
      @media @mobile-screen {
        order: 5;
      }
      img {
        height:100%;
        object-fit: cover;
      }
    }
    .goals_4 {
      grid-column: span 2;
      @media @mobile-screen {
        order: 7;
        max-height: initial;
      }
      img:first-child {
        display:block;
        @media @mobile-screen {
          display:none;
        }
      }
    }
    .goals_5 {
      grid-row: span 2;
      height: 100%;
      object-fit: cover;
      height: 724px;
      position: relative;
      top: -125px;
      @media @tablet-screen {
        height: 52vw;
        top: -8.8vw;
      }
      @media @mobile-screen {
        top:initial;
        order: 8;
        margin-bottom:0;
        height:initial;
      }
      img {
        object-fit: cover;
        height: 100%;
      }
    }
    .content-hover {
      position:relative;
      
      &:hover, &:focus {
        div {
          display: flex;
          align-items: center;
        }
      }
      div {
        position:absolute;
        left:0;
        top:0;
        height:100%;
        width:100%;
        background: rgba(224 51 35 / 85%);
        padding:24px;
        display:none;
        @media @tablet-screen {
          padding: 1.7vw;
        }
        @media @mobile-screen {
          padding: 16px;
        }
      }
    }
  }
</style>