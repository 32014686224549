<template>
  <Teleport to="head">
    <title>{{ $t("seo_title") }} | {{ $t("seo_title2") }}</title>
    <meta name="description" :content="$t('seo_desc')" />
    <meta name="keyword" :content="$t('seo_keyword')" />
  </Teleport>
  <div class="license header-gap">
    <div class="sub-banner">
      <div class="container">
        <h1 v-html="$t('about.license-title')"></h1>
        <p v-html="$t('about.license-sub-title')"></p>
      </div>
    </div>
    <div class="license-list">
      <div class="container custom">
        <h2 v-html="$t(`about.strictSupervision`)"></h2>
        <p v-html="$t(`about.strictSupervisionDesc`)"></p>
        <div class="input-group">
          <span class="categories" v-html="$t(`about.categories`)"></span>
          <div class="categories-wrapper">
            <select v-model="selectedCategory">
              <option value="" v-html="$t(`about.allCategories`)"></option>
              <option v-for="category in categories" :key="category" :value="category">
                {{ $t(`about.${category}`) }}
              </option>
            </select>
            <div class="icon"></div>
          </div>
        </div>
        <div class="wrapper">
          <div v-for="(image, index) in filteredImages" :key="index"
            :class='["image-card", { "landscape-license": image.name == "AE_RERA" || image.name == "ID_BAPPEBTI_FBL" || image.name == "ID_BAPPEBTI_SPA" || image.name == "KY_CIMA" }]'>
            <div>
              <img @click=" zoomImage(imageUrl + image.name + '.jpg')" :src="imageUrl + image.name + '.jpg'" alt="Image"
                class="pointer">
            </div>
            <div>
              <h3 v-html="$t(`about.${image.name}`)"></h3>
              <p v-html="$t(`about.${image.name}_desc`)"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal"  v-if="displayModal">
    <div class="target" :class="modalImageMobile" :style="{ width: resizeContainer, alignContent: alignCt }">
      <img class="icon" @click="closeZoom()" :src="imageUrl + 'modal-close.png'" />
      <img :src="getImage" class="target-img"  @click="toggleSize()"
        :style="{ cursor: cursorZoom }" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedCategory: '',
      imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/new/",
      brokerage: this.$t('about.brokerage'),
      wealthManagement: this.$t('about.wealthManagement'),
      paymentExchange: this.$t('about.paymentExchange'),
      images: [
        { name: 'US_SEC', category: "brokerage" },
        { name: 'US_FINRA', category: "brokerage" },
        { name: 'US_FINCEN', category: "paymentExchange" },
        { name: 'UK_FCA', category: "brokerage" },
        { name: 'CA_FINTRAC', category: "paymentExchange" },
        { name: 'AU_ASIC', category: ["brokerage", "wealthManagement"] },
        { name: 'ASIC', category: "paymentExchange" },
        { name: 'AU_AUSTRAC', category: "paymentExchange" },
        { name: 'AU_AUSTRAC-1', category: "paymentExchange" },
        //{ name: 'SG_MAS', category: "brokerage" },
        { name: 'HK_SFC', category: ["brokerage", "wealthManagement"] },
        { name: 'HK_Insurance_Broker', category: "wealthManagement" },
        { name: 'HK_Trust_Company', category: "wealthManagement" },
        { name: 'HK_TCSP', category: "wealthManagement" },
        { name: 'HK_DPMS', category: "brokerage" },
        { name: 'HK_MSO', category: "paymentExchange" },
        { name: 'HK_EAA', category: "wealthManagement" },
        { name: 'AE_RERA', category: "wealthManagement" },
        { name: 'CBUAE', category: "wealthManagement" },
        { name: 'AE_PSP', category: "paymentExchange" },
        { name: 'MY_Labuan_FSA-1', category: "brokerage" },
        { name: 'MY_Labuan_FSA-2', category: "brokerage" },
        { name: 'ID_BAPPEBTI_FBL', category: "brokerage" },
        { name: 'ID_BAPPEBTI_SPA', category: "brokerage" },
        { name: 'BVI_SFC', category: "wealthManagement" },
        { name: 'KY_CIMA', category: "wealthManagement" },
        { name: 'CY_SEC', category: "brokerage" },
      ],
      getImage: '',
      displayModal: false,
      isEnlarge: false,
      cursorZoom: 'zoom-in',
      resizeContainer: '35vw',
      alignCt: 'center',
      isMobile: false
    };
  },
  computed: {
    categories() {
      return ["brokerage", "wealthManagement", "paymentExchange"];
    },
    filteredImages() {
      return this.images.filter((image) =>
        this.selectedCategory === '' || image.category.includes(this.selectedCategory)
      );
    },
    modalImageMobile() {
      if (this.isMobile) {
        this.resizeContainer = '95vw'
      } else {
        this.resizeContainer = '35vw'
      }
    }
  },
  methods: {
    zoomImage(image) {
      this.displayModal = true;
      this.getImage = image;
      document.documentElement.style.overflow = 'hidden';
    },
    closeZoom() {
      this.displayModal = false;
      this.isEnlarge = false
      this.cursorZoom = 'zoom-in';
      if (!this.isMobile) {
        this.resizeContainer = '35vw'
      } else {
        this.resizeContainer = '95vw'
      }
      this.alignCt = 'center'
      document.documentElement.style.overflow = 'unset';
    },
    toggleSize() {
      if (!this.isMobile) {
        if (!this.isEnlarge) {
          this.isEnlarge = true
          this.cursorZoom = 'zoom-out';
          this.resizeContainer = '65vw'
          this.alignCt = 'unset'
        }
        else {
          console.log('flag1');
          this.isEnlarge = false
          this.cursorZoom = 'zoom-in';
          this.resizeContainer = '35vw'
          this.alignCt = 'center'
        }
      }

    },
    checkMobile() {
      if (window.innerWidth <= 834) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  },
  mounted() {
    this.checkMobile()
    window.addEventListener("resize", this.checkMobile);
  }
};
</script>

<style lang="less">
@import '../less/mediaQuery.less';

html[lang="sc"],
html[lang="tc"] {
  .license .license-list .input-group .categories {
    font-family: "Microsoft-YaHei-Heavy";
  }
}

.license {
  .sub-banner {
    background: url('https://cdn.dootech.io/doo/about/license.png') no-repeat center top;

    @media @mobile-screen {
      background: url('https://cdn.dootech.io/doo/about/license_mobile.png') no-repeat center top;
    }

    >.container {
      @media only screen and (max-width:1500px) and (min-width:835px) {
        max-width: 40%;
        margin: 0;
      }
    }
  }

  .license-list {
    padding-bottom: 160px;

    @media @mobile-screen {
      padding-bottom: 80px;

    }

    h2 {
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin-bottom: 24px;

      @media @mobile-screen {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom: 16px;
      }
    }

    .container {
      @media only screen and (max-width:1380px) {
        padding: 0 16px;
      }

      &.custom {
        max-width: 1329px;
      }

      >p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom: 80px;

        @media @mobile-screen {
          margin: 0 auto 40px;
        }
      }
    }

    .input-group {
      text-align: center;
      margin-bottom: 40px;

      @media only screen and (max-width:1380px) {
        text-align: center;
      }

      .categories {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #3e3a39;
        display: inline-block;
        margin-right: 16px;
        margin-bottom: 15px;

        @media only screen and (max-width:600px) {
          display: block;
        }
      }

      .categories-wrapper {
        display: inline-block;
        position: relative;
        max-width: 372px;
        width: 100%;
        display: inline-block;

        @media only screen and (max-width:600px) {
          max-width: 343px;
        }

        .icon {
          width: 24px;
          height: 18px;
          background: #ffffff;
          position: absolute;
          right: 43px;
          top: 14px;
          z-index: 1;
          pointer-events: none;

          @media only screen and (min-width:1381px) {
            right: 1px;
          }

          @media @mobile-screen {
            right: 38px;
          }

          @media only screen and (max-width:600px) {
            right: 1px;
          }

          @media only screen and (max-width:380px) {
            right: 1px;
          }

          &:before {
            content: '';
            width: 12px;
            height: 0px;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 10px solid #3d3938;
            transform: rotate(90deg);
            position: absolute;
            right: 8px;
            top: 9px;
          }
        }

        select {

          &:focus,
          &:active {
            &~.icon:before {
              transform: rotate(-90deg);
              right: 16px;
              top: -1px;
            }
          }
        }
      }

      select {
        padding: 12px 42px 12px 16px;
        border: solid 1px rgba(158, 158, 159, 0.4);
        width: 100%;
        height: 48px;
        font-family: SegoeUI;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #3e3a39;
        position: relative;
        cursor: pointer;

        @media only screen and (max-width:1380px) {
          max-width: 288px;
        }

        @media @mobile-screen {
          max-width: min(300px, 80%);
        }

        @media only screen and (max-width:600px) {
          max-width: 343px;
        }

        @media only screen and (max-width:380px) {
          max-width: 100%;
        }
      }
    }

    .wrapper {
      text-align: left;

      @media only screen and (min-width:600px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /*grid-template-rows: repeat(5, 1fr);*/
        grid-column-gap: 24px;
        grid-row-gap: 24px;
      }

      @media only screen and (min-width:990px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media only screen and (max-width:1380px) {
        text-align: center;
      }
    }

    .image-card {
      width: 100%;
      padding: 40px 24px 32px;
      border: solid 1px rgba(158, 158, 159, 0.4);
      background-color: #fff;
      text-align: center;
      margin: 0 auto;
      display: inline-flex;
      flex-direction: column;
      align-items: center;

      @media @mobile-screen {
        max-width: 343px;
        padding-top: 32px;
        padding-bottom: 24px;
        margin-bottom: 16px;
        display: block;
      }

      &.landscape-license {
        justify-content: center;

        @media only screen and (max-width: 1380px) {
          justify-content: flex-start;
        }

        >div {
          &:first-child {
            justify-content: center;
            min-height: 276px;

            @media only screen and (max-width: 1380px) {
              min-height: 227px;
            }
          }
        }

        img {
          max-width: initial;
          max-width: 300px;
          height: auto;

          @media @mobile-screen {
            max-width: 227px;
            height: auto;
          }
        }
      }

      >div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
      }

      h3 {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-top: 32px;
        margin-bottom: 8px;

        @media only screen and (max-width:1380px) {
          margin-top: 24px;
        }
      }

      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #9e9e9f;

        @media only screen and (max-width:679px) {
          max-width: 224px;
          margin: 0 auto;
        }
      }
    }

    img {
      max-width: 212px;
      width: 100%;
      height: 300px;
      object-fit: contain;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);

      @media only screen and (max-width:1380px) {
        max-width: 160px;
        width: 100%;
        height: 227px;
      }
    }

    img:hover {
      cursor: pointer;
    }
  }

}

.modal {
  background-color: #00000070;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: scroll;
  scrollbar-width: none;
}

.target {
  position: absolute;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vw;
  height: 90vh;
  max-width: 1330px;
}

.zoomed-mobile {
  width: 95vw;
}

.target-img {
  cursor: zoom-in;
  width: 100%;
  // height: 570px;
  // width: 410px;
}

.icon {
  cursor: pointer;
  float: right;
  width: 40px;
  height: 39.4px;
}
</style>