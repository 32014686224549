<template>
    <section id="license">
        <div class="container-image" v-if="isMobile == false">
            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/License.png" alt="license-img"
                class="license">
            <div class="wrapper">
                <p class="title" v-t="'license.title'"></p>
                <p class="sub-title" v-t="'license.sub-title'"></p>
                <RouterLink :to="`/${$i18n.locale}/license`" v-t="'license.learn-more'"
                                :class="currentRoute.name === `license` ? 'active' : ``" page="license" class="know-more-btn btn"></RouterLink>
            </div>
        </div>
        <div class="container-image-mobile" v-else>
            <div class="container">
                <p class="title" v-t="'license.title'"></p>
                <p class="sub-title" v-t="'license.sub-title'"></p>
                <RouterLink :to="`/${$i18n.locale}/license`" v-t="'license.learn-more'"
                                :class="currentRoute.name === `license` ? 'active' : ``" page="license" class="know-more-btn btn"></RouterLink>
            </div>
            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/License-mobile.png" alt="license-img"
                class="license">
        </div>

    </section>
</template>

<script>

export default {
    setup() {
        return {
            locationOrigin: window.location.origin,
        };
    },
    data() {
        return {
            isMobile: false,
            currentRoute: this.$router.currentRoute
        }
    },
    methods: {
        checkMobile() {
            if (window.innerWidth <= 835) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        }
    },
    mounted() {
        this.checkMobile()
        window.addEventListener("resize", this.checkMobile);
    }
}

</script>


<style scoped lang="less">
@import '../../less/mediaQuery.less';
#license {
    margin-top: 138px;
    margin-bottom: 132px;
    @media @mobile-screen{
        margin-top: 80px;
        margin-bottom: 80px;
    }
    
    .container-image-mobile {
        position: relative;
        display: inline-block;
        height: 750px;
        @media @mobile-screen{
            height: auto;
        }
        
        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding:0 16px 300px;

            .title {
                margin: auto;
                
                font-size: 28px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #3e3a39;
                margin-bottom: 16px;


            }

            .know-more-btn {
                background-color: #e03323;
                border: none;
                width: 150px;
                height: 48px;
                
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;


            }

            .sub-title {
                width: 100%;
                margin: 0;
                margin-bottom: 32px;
                
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #3e3a39;
                @media @mobile-screen {
                    padding: 0;
                }

            }

        }

        .license {
            width: 100%;
            height: 750px;
            object-fit: cover;
            @media @mobile-screen{
                height: 580px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: -1;
            }
        }
    }

    .container-image {
        position: relative;
        display: inline-block;

        .wrapper {
            position: absolute;
            top: 31%;
            left: 59%;
            padding-right: 16px;
            text-align: left;
        }
        .title {
            margin: 0;
            
            font-size: 48px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3e3a39;
            text-align: left;
            margin-bottom:24px;
        }

        .know-more-btn {
            background-color: #e03323;
            border: none;
            width: 150px;
            height: 48px;
            
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .sub-title {
            max-width: 517px;
            margin: 0;
            
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #3e3a39;
            text-align: left;
            margin-bottom:40px;
        }

        .license {
            max-width: 2560px;
            width: 100%;
            height: 700px;
            object-fit: cover;
        }
    }
}
</style>