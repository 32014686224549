<template>
  <section id="esg-block">
    <div class="container">
      <p class="title" v-t="'esg.title'"></p>
      <p class="sub-title" v-t="'esg.subtitle'"></p>
      
      <div class="carousel-container">
        <span @click="prev">
          <img :src="ArrowLeft" class="carousel-custom-control" alt="prev icon" />
        </span>
        <div class="carousel">
          <carousel ref="carouselRef" v-model="currentSlide" :items-to-show="1" :autoplay="5000" :wrap-around="true">
            <slide key="1">
              <img :src="Image1" alt="" />
              <img :src="Image1Mobile" alt=""/>
            </slide>
            <slide key="2">
              <img :src="Image2" alt="" />
              <img :src="Image2Mobile" alt="" />
            </slide>
            <slide key="3">
              <img :src="Image3" alt="" />
              <img :src="Image3Mobile" alt="" />
            </slide>
          </carousel>
        </div>
        <span @click="next">
          <img :src="ArrowRight" class="carousel-custom-control" alt="next icon" />
        </span>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import Image1 from "@/assets/ESG/ESG-Unicef@3x.png";
import Image2 from "@/assets/ESG/ESG-KCL@3x.png";
import Image3 from "@/assets/ESG/ESG-ACSFWA@3x.png";
import Image4 from "@/assets/ESG/ESG-SOS@3x.png";
import Image1Mobile from "@/assets/ESG/ESG-Unicef-With-Logo@2x.png";
import Image2Mobile from "@/assets/ESG/ESG-KCL-With-Logo@2x.png";
import Image3Mobile from "@/assets/ESG/ESG-ACFSWA-With-Logo@2x.png";
import Image4Mobile from "@/assets/ESG/ESG-SOS-With-Logo@2x.png";
import ArrowLeft from "@/assets/icons/arrow-left.svg";
import ArrowRight from "@/assets/icons/arrow-right.svg";

const carouselRef = ref();
const currentSlide = ref(0);

const next = () => carouselRef.value.next();
const prev = () => carouselRef.value.prev();
</script>

<style lang="less">
@import '../../less/mediaQuery.less';
#esg-block {
  margin: 0 0 100px;
  @media @mobile-screen {
    margin: 0 16px;
  }
  @media only screen and (max-width: 500px) {
    margin: 0 16px;
  }
  .container {
    max-width:1330px;
    .title {
      padding-top: 160px;
      
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin-bottom: 24px;
      margin-top: 0px;
      @media @mobile-screen {
        font-size: 28px;
        padding-top: 80px;
        margin-bottom: 16px
      }
      @media only screen and (max-width: 500px) {
        font-size: 28px;
      }
    }
    .sub-title {
      
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      max-width: 788px;
      width: 100%;
      margin: auto;
      margin-bottom: 80px;
      @media @mobile-screen {
        margin-bottom: 40px;
        width: auto;
      }
    }
  }

  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel {
    width: 100%;
    position:relative;
    @media @mobile-screen {
      height:auto
    }
    img {
      width: 100%;
      object-fit: contain;
      &:last-child {
        display:none;
      }
      @media @mobile-screen {
        display:none;

        &:last-child {
          display:block;
        }
      }
    }
  }

  .carousel__pagination-button::after {
    width: 6px;
    height: 6px;
  }
  .carousel__pagination-button--active::after {
    background-color: #e03323;
  }
  .carousel__prev,
  .carousel__next {
    display: none;
  }
  .carousel__pagination {
    margin: 0 !important;
    padding: 0 !important;
    margin-top:32px !important;
  }

  .carousel-custom-control {
    margin: 16px;
    width: 44px;
    height: 64px;
    cursor:pointer;
  }

}
</style>
