<template>
    <section id="highlight">
      <div class="container">
        <h2>{{ t("aufc.highlights") }}</h2>
        <p>{{ t("aufc.highlights-desc") }}</p>

        <template v-if="list.length">
          <div class="wrapper">
            <ul class="list">
              <li v-for="(item, index) in list" :key="index" :class="'year' + item.year + (item.year == 2023 ? ' active' : '')">
                <a @click="triggerYear(item.year, item.components)">{{ item.year }}</a>
              </li>
            </ul>
            <component v-if="contentRef" :is="contentRef" />
            <!--template v-else>
              <div class="content cover">
                <picture>
                  <source media="(max-width:650px)" srcset="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/HIGHLIGHTS_cover_mobile.png">
                  <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/HIGHLIGHTS_cover.png" alt="">
                </picture>
              </div>
            </template-->
          </div>
        </template>
      </div>
    </section>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import Year2023 from '@/components/AUFC/Journey/Year2023.vue'
  import Year2022 from '@/components/AUFC/Journey/Year2022.vue'
  import Year2021 from '@/components/AUFC/Journey/Year2021.vue'
  import Year2020 from '@/components/AUFC/Journey/Year2020.vue'
  import Year2018 from '@/components/AUFC/Journey/Year2018.vue'
  import Year2017 from '@/components/AUFC/Journey/Year2017.vue'
  import Year2016 from '@/components/AUFC/Journey/Year2016.vue'
  import Year2014 from '@/components/AUFC/Journey/Year2014.vue'
  import Year2013 from '@/components/AUFC/Journey/Year2013.vue'
  import Year2008 from '@/components/AUFC/Journey/Year2008.vue'
  import Year2003 from '@/components/AUFC/Journey/Year2003.vue'

  const ImageUrl = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/"
  
  const { t } = useI18n();
  const list = [
    {
      year: 2023,
      components: Year2023
    },
    {
      year: 2022,
      components: Year2022
    },
    // {
    //   year: 2021,
    //   components: Year2021
    // },
    // {
    //   year: 2020,
    //   components: Year2020
    // },
    // {
    //   year: 2018,
    //   components: Year2018
    // },
    // {
    //   year: 2017,
    //   components: Year2017
    // },
    {
      year: 2016,
      components: Year2016
    },
    {
      year: 2014,
      components: Year2014
    },
    {
      year: 2013,
      components: Year2013
    },
    {
      year: 2008,
      components: Year2008
    },
    {
      year: 2003,
      components: Year2003
    }
  ];
  const contentRef = ref(Year2023);

  const triggerYear = (year, components) => {
    const prevYear = document.querySelector('.list .active') || false;
    if(prevYear){
      prevYear.classList.remove('active');
    }

    const selectedYear = document.querySelector('.year' + year);
    selectedYear.classList.add('active');

    contentRef.value = components;
  }



  
  // onMounted(() => {
  //   handleWindowChangeSize();
  //   window.addEventListener("resize", handleWindowChangeSize);
  // });
  
  // onUnmounted(() => {
  //   window.removeEventListener("resize", handleWindowChangeSize);
  // });
  </script>

<style lang="less">
  @import '@/less/mediaQuery.less';
  #highlight {
    padding-top:180px;
    padding-bottom:240px;
    @media @mobile-screen {
      padding-top:100px;
      padding-bottom:0px;
    }
    h2 {
      font-size: 76px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: left;
      color: #3e3a39;
      margin-bottom:24px;
      text-align: center;
      @media @mobile-screen {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: #3e3a39;
        margin-bottom: 16px;
      }
    }
    .container {
      > p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom:80px;
        @media @mobile-screen {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #3e3a39;
          margin-bottom:40px;
        }
      }
    }
    .wrapper {
      display:flex;
      .list {
        list-style: none;
        padding-left:0;
        padding-right:170px;
        @media @mobile-screen {
          padding-right:24px;
        }
        li.active {
          a {
            font-family: DINPro-CondBold;
            font-size: 100px;
            font-weight: bold;
            font-stretch: condensed;
            font-style: normal;
            line-height: 80px;
            letter-spacing: normal;
            color: #e03323;
            position:relative;
            padding-right:26px;
            @media @mobile-screen {
              font-size: 24px;
              font-weight: bold;
              font-stretch: condensed;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #e03323;
              padding-right:11px;
            }
            &:before {
              content:'';
              position:absolute;
              right:0;
              top:50%;
              width: 0;
              height: 0;
              border-top: 8px solid transparent;
              border-right: 14px solid #e03323;
              border-bottom: 8px solid transparent;
              @media @mobile-screen {
                border-top: 4px solid transparent;
                border-right: 5.4px solid #e03323;
                border-bottom: 4px solid transparent;
                margin-top:-5px;
              }
            }
          }
        }
        a {
          font-size: 32px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #878793;
          margin-bottom:40px;
          display:block;
          min-width:187px;
          text-align: right;
          @media @mobile-screen {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #878793;
            min-width:50px;
            margin-bottom: 16px;
          }
        }
      }
      .content.cover {
        img {
          width:100%;
        }
      }
    }

    .content {
      width:100%;
      height:100%;
      .bg {
        background-size:cover;
        text-align: left;
        padding:32px 47px 32px 32px;
        @media only screen and (min-width:835px) {
          min-height:589px;
        }
        @media @mobile-screen {
          background:none;
          padding:0;
        }
        &:not(:first-child){
          margin-top:48px;
        }
        &:nth-child(even) {
          @media only screen and (min-width:835px) {
            display: flex;
            flex-direction: column;
            align-items: end;
            background-position:right top;
          }
          p {
            @media only screen and (min-width:835px) {
              max-width:310px;
            }
          }
          .logo-bg {
            .logo {
              @media @mobile-screen {
                max-width: 20vw;
                position:absolute;
                top: 9vw;
                left: initial;
                right: 3vw;
              }
            }
          }
        }
        .logo-bg {
          margin-bottom:20px;
          position:relative;
          @media @mobile-screen {
            margin-bottom:16px;
          }
          .logo {
              max-width:133px;
              @media @mobile-screen {
                max-width: 20vw;
                position:absolute;
                top: 9vw;
                left: 3vw;
              }
          }
        }
        > div:last-child {
          @media only screen and (min-width:835px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            flex: 1;
          }
        }
        p {
          font-size: 19px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          margin-bottom:16px;
          padding-left:24px;
          position:relative;
          @media only screen and (min-width:835px) {
            max-width:291px;
          }
          @media @mobile-screen {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #3e3a39;
            padding-left:20px;
            margin-bottom:12px;
          }
          &:before {
            content:'';
            width:0;
            height:0;
            position:absolute;
            left:0;
            top: 4px;
            border-top: 8px solid transparent;
            border-left: 16px solid #ffffff;
            border-bottom: 8px solid transparent;
            @media @mobile-screen {
              border-top: 6px solid transparent;
              border-left: 12px solid #e03323;
              border-bottom: 6px solid transparent;
            }
          }
        }
      }
    }
  }
</style>