<template>
    <section id="footer-body">
        <div class="container">
            <div class="wrapper-top" v-if="isMobile == false">
                <div class="footer-nav">
                    <button v-for="item, index in filteredInfoList" class="btn"
                        :class="component == index ? 'active' : ''" @click="component = index"> {{
                            item?.[0]?.translate }} </button>
                </div>
                <div class="content">
                    <p class="content-description" v-for="desc in filteredInfoList[component].slice(1)">{{
                        desc.translate }}</p>
                </div>
                <div class="border-btm"></div>
            </div>

            <div class="wrapper-top-mobile" v-else>
                <div v-for="item, index in filteredInfoList">
                    <div :class="index == 0 ? 'border-btm-first' : 'border-btm'"></div>
                    <div class="container-navigation ">
                        <div class="txt-wrapper" @click="toggleDropdown(index)">
                            <p>{{ item?.[0]?.translate }}</p>
                            <img class="icon-dropdown"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                                alt="dropdown">
                        </div>
                        <div class="container-dropdown" v-if="sectionOpened.includes(index)">
                            <p class="description" v-for="desc in item.slice(1)">{{ desc.translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="border-btm"></div>
                <div class="container-navigation ">
                    <div class="txt-wrapper" @click="toggleDooNotice">
                        <p>{{ info?.doo_entities_notice?.[0]?.translate }}</p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="riskSection">
                        <div class="entities-container" style="padding:0px;" v-if="riskSection === true">
                            <div class="entities-content">
                                <div v-for="(entity) in entitiesNotice" class="container-detail">
                                    <p v-for="(text) in entity" :class="text.is_sub_title == 1 ? 'title' : 'desc'">{{
                                        text.translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="notice-nav" @click="toggleDooNotice" v-if="isMobile == false">
                <div class="btn entities-notice">
                    <p class="brand-entities">{{ info?.doo_entities_notice?.[0]?.translate }}</p>
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Close-Light-Grey.svg"
                        alt="close" v-show="this.riskSection == true">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown-Light-Grey.svg"
                        alt="close" v-show="this.riskSection == false">
                </div>
                <div class="border-btm"></div>
            </div>

            <div name="height-transition" mode="out-in" v-if="isMobile == false">
                <div class="entities-container" v-if="riskSection === true">
                    <div class="entities-content">
                        <div v-for="(entity, index) in entitiesNotice" class="container-detail">
                            <p v-for="(text) in entity" :class="text.is_sub_title == 1 ? 'title' : 'desc'">{{
                                text.translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ref } from 'vue';

export default {
    props: {
        info: {},
    },
    computed: {
        filteredInfoList() {
            const infoList = this.info
            const excludeList = ['copyright', 'doo_entities_notice'];
            const filteredInfo = Object.keys(infoList)
                .filter(key => !excludeList.includes(key))
                .reduce((obj, key) => {
                    return {
                        ...obj,
                        [key]: this.info[key]
                    };
                }, {});
            return Object.values(filteredInfo);
        },
    },
    data() {
        return {
            component: 0,
            riskSection: true,
            isMobile: false,
            sectionOpened: [],
            entitiesNotice: []
        }
    },
    methods: {
        checkMobile() {
            this.isMobile = window.innerWidth <= 835;
        },
        toggleDropdown(index) {
            if (!this.sectionOpened.includes(index)) {
                this.sectionOpened.push(index);
                return
            }
            const indexToBeRemoved = this.sectionOpened.indexOf(index);
            this.sectionOpened.splice(indexToBeRemoved, 1);
        },
        toggleDooNotice() {
            this.riskSection = !this.riskSection
        },
        createEntitiesNoticeList() {
            const list = ref([])
            const entitiesNoticeList = this.info?.doo_entities_notice
            entitiesNoticeList?.map((item, index) => {
                if (index == 0) {
                    return
                }
                if (item.is_sub_title == 1 && list.value.length != 0) {
                    this.entitiesNotice.push(list.value);
                    list.value = [];
                }
                list.value.push(item)
                if (index == entitiesNoticeList.length - 1) {
                    this.entitiesNotice.push(list.value);
                }
            })
        }
    },
    watch: {
        info: {
            handler(newVal, oldVal) {
                if (oldVal == undefined) {
                    this.createEntitiesNoticeList()
                }
            },
            deep: true
        }
    },
    mounted() {
        this.checkMobile()
        window.addEventListener("resize", this.checkMobile);
    }
}
</script>

<style lang="less" scoped>
@import '../../../less/mediaQuery.less';

.height-transition-enter-from,
.height-transition-leave-to {
    height: 0;
}

.height-transition-enter-active,
.height-transition-leave-active {
    transition: height 1s ease;
}

.height-transition-enter-to,
.height-transition-leave-from {
    height: 3040px;
}



#footer-body {
    background-color: #000000;

    .wrapper-top-mobile {
        margin: 0;

        .container-navigation {
            .txt-wrapper {
                display: flex;
                justify-content: space-between;
                cursor: pointer;

                p {

                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                }
            }

            .container-dropdown {
                .description {

                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    text-align: left;
                    padding-top: 16px;

                    p {
                        padding-bottom: 16px;
                    }
                }
            }
        }

        .border-btm-first {
            padding-top: 40px;
            margin-bottom: 16px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        .border-btm {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .border-btm-last {
            padding-top: 40px;
            margin-top: 16px;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
        }

    }

    .wrapper-top {
        padding: 40px 0px 20px;

        .footer-nav {
            text-align: left;
            padding: 0 0 20px;
            margin-bottom: 24px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            display: flex;
            align-items: center;
            justify-content: start;

            .active::after {
                content: url(https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Close-Red.svg);
                position: absolute;
                transform: translate(-50%, -50%);
                top: 150%;
                left: 50%;
                width: 20px;
                height: 12px;
            }

            .btn {
                background-color: transparent;
                border: none;

                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
                position: relative;
                margin-right: 120px;
            }

            .btn:hover {
                cursor: pointer;
            }
        }

        .content {
            text-align: left;

            .content-description {
                text-align: left;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
                margin-bottom: 16px;
            }
        }

        section {
            padding: 0;
        }

        .border-btm {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    .notice-nav-mobile {
        padding: 0 16px;

        .border-btm {
            display: flex;
            padding: 0 160px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        .btn {
            display: flex;

            img {
                width: 24px;
                height: 24px;
                padding-bottom: 20px;
                padding-top: 20px;
            }

            p {
                margin: 0;
                padding-bottom: 20px;
                padding-top: 20px;
                text-align: left;

                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
            }
        }

        .btn:hover {
            cursor: pointer;
        }
    }

    .notice-nav {

        .border-btm {
            display: flex;
            padding: 0 160px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        .btn {
            display: flex;

            img {
                width: 24px;
                height: 24px;
                margin-top: -1px;
                margin-left: 10px;
            }

            p {
                margin: 0;
                padding-bottom: 20px;
                text-align: left;

                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
            }
        }

        .btn:hover {
            cursor: pointer;
        }
    }

    .entities-container {
        padding: 0 0 24px;

        @media @mobile-screen {
            padding: 0 16px;
        }

        .entities-content {
            display: grid;
            grid-column-gap: 28px;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;

            @media @mobile-screen {
                padding-top: 16px;
                grid-row-gap: 24px;
                grid-template-columns: repeat(1, 1fr)
            }


            .container-detail {
                p {
                    text-align: left;
                }

                .title {
                    margin: 24px 0 16px 0;

                    @media @mobile-screen {
                        margin: 0px 0 16px 0;
                    }


                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #ffff;
                }

                .desc {

                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    margin-bottom: 16px;
                }
            }

            .container-left {
                padding-right: 28px;
            }
        }

        .border-btm {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
    }


}
</style>