<template>
    <section id="global-presence">
        <div class="container">
            <div class="header">
                <p class="title" v-t="globalOffice ? 'global-presence.title' : 'home-global-presence.title' "></p>
                <p class="sub-title" v-t="globalOffice ? 'global-presence.sub-title' : 'home-global-presence.sub-title'"></p>
            </div>
        </div>
        <div class="map-wrapper">
            <div class="map-container">
                <img class="map" src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/map-v2.png"
                    alt="map-global">
                <div class="london container">
                    <div class="container-details">
                        <p class="details london-add">Berkeley Suite 35 Berkeley Square, Mayfair, London, England, W1J 5BF
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states">London</p>
                        <p class="country">UNITED KINGDOM</p>
                    </div>
                </div>
                <div class="dalas container">
                    <div class="container-details">
                        <p class="details dalas-add">5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states">Dallas</p>
                        <p class="country">UNITED STATES</p>
                    </div>
                </div>
                <!-- <div class="cyprus container">
                    <div class="container-details">
                        <p class="details cyprus-add">Agias Fylakseos 155, First Floor, Office 102, 3083 Limassol, Cyprus
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states">Cyprus</p>
                    </div>
                </div> -->
                <div class="egypt container">
                    <div class="container-details">
                        <p class="details egypt-add">67 El-Horeya, Almazah, Heliopolis, Cairo Governorate 4461122, Egypt
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states">Egypt</p>
                    </div>
                </div>
                <div class="dubai container">
                    <div class="container-details">
                        <p class="details dubai-add">Unit 705A, B05, Building BC04, RAKEZ Business Zone - FZ Ras Al Khaimah, United Arab Emirates
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states">Dubai</p>
                        <p class="country">UNITED ARAB EMIRATES</p>
                    </div>
                </div>
                <div class="hong-kong container">
                    <div class="container-details">
                        <p class="details hong-kong-add">28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states hk">HONG KONG</p>
                        <p class="country">CHINA</p>
                    </div>
                </div>
                <div class="thailand container">
                    <div class="container-details">
                        <p class="details thailand-add">28th Floor, Rama 9 Road, Huaykwang Sub-district, Huaykwang District, Bangkok
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states">THAILAND</p>
                    </div>
                </div>
                <div class="kuala-lumpur container">
                    <div class="container-details">
                        <p class="details kuala-lumpur-add">Level 3, Tower 8, Avenue 5, The Horizon Phase 2, Bangsar South City, No. 8 Jalan Kerinchi, 59200 Kuala Lumpur, Malaysia
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states">KUALA LUMPUR</p>
                        <p class="country">MALAYSIA</p>
                    </div>
                </div>
                <div class="singapore container">
                    <div class="container-details">
                        <p class="details singapore-add">#24-01A, Millenia Tower, One Temasek Avenue, Singapore 039192
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states">SINGAPORE</p>
                    </div>
                </div>
                <div class="south-africa container">
                    <div class="container-details">
                        <p class="details south-africa-add">155 West St, Sandton, Johannesburg, GP, 2031, South Africa
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states">SOUTH AFRICA</p>
                    </div>
                </div>
                <div class="sydney container">
                    <div class="container-details">
                        <p class="details sydney-add"> ‘Tower 1' Suite 1302 Level 13, 475 Victoria Avenue, Chatswood NSW 2067, Australia
                        </p>
                    </div>
                    <div class="states-container">
                        <p class="states">SYDNEY</p>
                        <p class="country">AUSTRALIA</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    let globalOffice = window.location.href.includes('/globalOffice')
</script>

<style lang="less" scoped>
@import '../../less/mediaQuery.less';
#global-presence {
    margin-bottom: 100px;

    .container {
        padding: 0 16px;
    }
    .header {
        margin-bottom: 80px;

        .title {
            margin-top: 0;
            
            font-size: 48px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #3e3a39;
            margin-bottom: 24px;
        }

        .sub-title {
            max-width: 910px;
            margin: auto;
            
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #3e3a39;
        }
    }

    .map-wrapper {
        overflow-x:auto;
        .map-container {
            position: relative;
            display: inline-block;
            max-height: 757px;
            width:1187px;
            margin: auto;
            padding-left: 16px;
            padding-right: 16px;

            .map {
                height: 600px;
            }

            .london {
                top: 17.3%;
                left: 44.2%;
            }

            .dalas {
                top: 29.5%;
                left: 10.6%;
            }

            .cyprus {    
                top: 29.3%;
                left: 51.7%;
            }

            .egypt {
                top: 36.7%;
                left: 53%;
            }

            .dubai {
                top: 38%;
                left: 61.2%;
            }
            
            .hong-kong {
                top: 40%;
                left: 81%;
                &:hover, &:focus {
                    .container-details {
                        transform: translate(-39%, -106%) !important;
                    }

                }
                .container-details {
                    transform: translate(-59%, -38%) scale(0.2) !important;
                }
            }
            
            .thailand {
                top: 46%;
                left: 77.8%;
                &:hover, &:focus {
                    .container-details {
                        transform: translate(-39%, -106%) !important;
                    }

                }
                .container-details {
                    transform: translate(-60%, -37%) scale(0.2) !important;
                }
            }
            
            .kuala-lumpur {
                top: 53.5%;
                left: 77.9%;
                &:hover, &:focus {
                    .container-details {
                        transform: translate(-39%, -106%) !important;
                    }

                }
                .container-details {
                    transform: translate(-61%, -38%) scale(0.2) !important;
                }
            }
            
            .singapore {
                top: 61.2%;
                left: 79.4%;
                z-index: 1;
                &:hover, &:focus {
                    .container-details {
                        transform: translate(-38%, -114%)  !important;
                    }

                }
                .container-details {
                    transform: translate(-66%, -34%) scale(0.2) !important;
                }
            }

            .seychelles {
                top: 61.2%;
                left: 62.6%;
            }

            .mauritius {
                top: 70%;
                left: 64%;
                z-index:1;
            }

            .south-africa {
                top: 80%;
                left: 50.6%;
                z-index: 1;
            }

            .vanuatu {
                top: 65%;
                left: 95.4%;
                z-index: 2;
                .container-details {
                    transform: translate(-65%, -32%) scale(0.2) !important;
                    &:after {
                        left: 88% !important;
                    }
                }
            }

            .sydney {
                top: 81.5%;
                left: 93.4%;
                z-index:2;
                .container-details {
                    transform: translate(-60%, -34%) scale(0.2) !important;
                    &:after {
                        left: 88% !important;
                    }
                }
            }

            .container {
                position: absolute;
                height: 25px;
                width: 25px;
                background-color: transparent;
                display: inline-block;

                .container-details {
                    // transform: translate(9999px);
                    opacity: 0;
                    width: 290px;
                    position: relative;
                    border-top: 4px solid #e03323;
                    display: flex;
                    background-color: #fff;
                    flex-direction: column;
                    align-items: center;
                    color: #3e3a39;
                    box-shadow: 0 4px 16px 0 rgba(62, 58, 57, 0.1);
                    transform: translate(-56%, -39%) scale(0.2);

                    .details {
                        font-family: SegoeUI;
                        font-size: 16px;
                        padding: 12px 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: left;
                        color: #3e3a39;
                    }
                }

                .container-details:after {
                    content: '';
                    position: absolute;
                    bottom: 13px;
                    left: 50%;
                    width: 0;
                    height: 0;
                    border: 8.5px solid transparent;
                    border-top-color: #fff;
                    border-bottom: 0;
                    margin-left: -20px;
                    margin-bottom: -20px;

                }

                .states-container {
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 51%;
                    right: -141%;

                    p {
                        margin: 0;
                    }

                    .states {
                        font-family: SegoeUI;
                        font-size: 16px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: right;
                        color: #3e3a39;
                        width: 119px;
                        text-transform: uppercase;
                    }

                    .country {
                        white-space: nowrap;
                        font-family: SegoeUI;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: right;
                        color: #3e3a39;
                        position: absolute;
                        right: 0;
                        top: 21px;
                    }
                }

            }

            .container:hover {
                &.vanuatu {
                    .container-details {
                        transform: translate(-76%, -106%) !important;
                    }
                }
                &.sydney {
                    .container-details {
                        transform: translate(-77%, -106%) !important;
                    }
                }
                .container-details {
                    transform: translate(-39%, -106%);
                    opacity: 1;
                    transition: opacity 0.5s;
                }
            }
        }
    }
}

//mobile styling

@media @mobile-screen {
    
    #global-presence {
        margin-bottom:80px;
        .header {
            margin-bottom: 40px;
            .title {
                max-width: 343px !important;
                margin: auto;
                font-size: 28px;
            }
            
            .sub-title {
                margin: auto;
                font-size: 16px;
                padding-top: 16px;
            }
        }
    }
}
@media only screen and (max-width:505px) {
    #global-presence {
        .header {
            .sub-title {
                max-width: 343px;
            }
        }
    }
}
</style>