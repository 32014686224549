<template>
    <Teleport to="head">
        <title>{{ $t("seo_title") }} | {{ $t("seo_title2") }}</title>
        <meta name="description" :content="$t('seo_desc')" />
        <meta name="keyword" :content="$t('seo_keyword')" />
    </Teleport>
    <div class="contact-us header-gap">
        <div class="sub-banner">
            <div class="container">
                <h1 v-t="'contact_us.title'"></h1>
                <p v-t="'contact_us.sub_title'"></p>
            </div>
        </div>
        <div class="time_zone">
            <div class="container">
                <h2 v-t="'contact_us.time_zone_title'"></h2>
                <p v-t="'contact_us.time_zone_description'"></p>
            </div>
            <div class="time_zone_container">
                <div class="container time_zone_grid">
                    <div class="country" v-for="item in timeZoneList " :key="item.country">
                        <AnalogClock :time-zone-off-set="item.timeZoneOffSet" />
                        <p class="title" v-t="`contact_us.${item.country}`"></p>
                        <p class="gtm">{{ item.timeZone }}</p>
                    </div>
                </div>
                <carousel :items-to-show="1" :autoplay="5000" :wrap-around="true" class="carousel">
                    <slide v-for="( item, index ) in timeZoneList " :key="index">
                        <div class="country">
                            <AnalogClock :time-zone-off-set="item.timeZoneOffSet" />
                            <p class="title" v-t="`contact_us.${item.country}`"></p>
                            <p class="gtm">{{ item.timeZone }}</p>
                        </div>
                    </slide>
                    <template #addons>
                        <navigation />
                        <pagination />
                    </template>
                </carousel>
            </div>
        </div>
        <div class="form-container container">

            <div class="detail">
                <div class="email-list">
                    <div class="email" v-for="item, index in getEmailList()">
                        <img :class="index == 0 ? '' : 'icon-hidden'"
                            src="https://cdn.dootech.io/doo/contact_us/Email.svg" />
                        <p>{{ item.translate }}</p>
                    </div>
                </div>
                <div class="phone-list" v-for="item, index in getPhoneNumberList()">
                    <img :class="index == 0 ? '' : 'icon-hidden'" src="https://cdn.dootech.io/doo/contact_us/Phone.svg" />
                    <div class="tel">
                        <p class="country">{{ item[0] }}</p>
                        <p>{{ item[1] }}</p>
                    </div>
                </div>

            </div>
            <div class="form-wrapper">
                <FormKit type="form" :value="initialState" @submit="handleSubmit" id="contactUsForm" :actions="false"
                    #default="{ state: { valid, submitted }, disabled, loading }"
                    incomplete-message="Please fill up all the fields.">
                    <div class="form">
                        <p class="title" v-t="'contact_us.form_title'"></p>
                        <FormKit type="text" name="lastName" :label="t('contact_us.last_name')"
                            validation="required:contact_us.last_name" maxlength="225"
                            :validation-messages="{ required: requiredMessage }" />
                        <FormKit type="text" name="firstName" :label="t('contact_us.first_name')"
                            validation="required:contact_us.first_name" maxlength="225"
                            :validation-messages="{ required: requiredMessage }" />
                        <FormKit type="text" name="tel" outer-class="phone" :label="t('contact_us.tel')"
                            validation="required:contact_us.tel|number" maxlength="225"
                            :validation-messages="{ required: requiredMessage, number: numberMessage }" inputmode="tel" />
                        <FormKit type="text" name="email" outer-class="email" :label="t('contact_us.email')"
                            validation="required:contact_us.email|email" maxlength="225"
                            :validation-messages="{ required: requiredMessage, email: emailMessage }" inputmode="email" />

                        <FormKit type="text" name="company" :label="t('contact_us.company_name')" outer-class="companyName"
                            maxlength="225" />

                        <FormKit type="checkbox" :label="t('contact_us.query')" name="inquiryType" outer-class="enquiryType"
                            @input="handleCheckbox" :options="[
                                { value: t('contact_us.service_consultation'), label: t('contact_us.service_consultation'), attrs: { disabled: checkboxValidation(t('contact_us.service_consultation')) } },
                                { value: t('contact_us.business_cooperation'), label: t('contact_us.business_cooperation'), attrs: { disabled: checkboxValidation(t('contact_us.business_cooperation')) } },
                                { value: t('contact_us.media_interview'), label: t('contact_us.media_interview'), attrs: { disabled: checkboxValidation(t('contact_us.media_interview')) } }
                            ]" validation="+max:1|+min:1" :validation-messages="{ min: minSelectionMessage }" />

                        <FormKit type="checkbox" :label="t('contact_us.brand')" name="interestedBranding"
                            outer-class="brandType"
                            :options="['Doo Group', 'Doo Clearing', 'Doo Financial', 'Doo Wealth', 'Doo Property', 'Doo Payment',
                                'FinPoints', 'Doo Academy', 'Doo Health', 'Doo Consulting', 'Doo Cloud', 'Doo Digital Plus']"
                            validation="+min:1" :validation-messages="{ min: minSelectionMessage }" />

                        <div class="col-span-2">
                            <FormKit type="textarea" name="message" :label="t('contact_us.message')"
                                input-class="min-height-200" validation="required:contact_us.message" maxlength="225"
                                :validation-messages="{ required: requiredMessage }" />
                        </div>

                        <div class="col-span-2 h-captcha" v-if="hCaptachaKey">
                            <VueHcaptcha ref="hCaptchaComponent" :sitekey="hCaptachaKey" @verify="onVerify"
                                @expired="onExpire" @challenge-expired="onChallengeExpire" @error="onError"
                                @reset="onReset" />
                        </div>
                        <div class="col-span-2 button-container">
                            <FormKit type="submit" :label="t('contact_us.submit')"
                                :disabled="!(valid && hCaptcha.verified) || disabled" wrapper-class="button-wrapper">
                            </FormKit>
                            <p class="submit-result" :class="submitStatus ? 'green' : 'red'" v-if="formSubmitComplete">
                                {{ submitStatus ? t('contact_us.submit_success') : t('contact_us.submit_failed') }}
                            </p>
                        </div>
                    </div>
                </FormKit>
            </div>
        </div>
    </div>
</template>

<script setup>
import AnalogClock from "@/components/AnalogClock.vue"
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import '@formkit/themes/genesis'
import { useI18n } from "vue-i18n";
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'
import { ref } from "vue";
import { getFooter, getHCaptachaKey, sendEmail } from '@/api/publicCms'
import { onMounted } from "vue";
import { reset } from '@formkit/core'

const { t } = useI18n()
const { locale } = useI18n();
const submitStatus = ref(true)
const formSubmitComplete = ref(false)
const selectedOption = ref(null);
const contactInfo = ref([])
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const initialState = {
    company: "",
    email: "",
    firstName: "",
    inquiryType: [],
    interestedBranding: [],
    lastName: "",
    message: "",
    tel: ""
};

const hCaptachaKey = ref()
const hCaptchaComponent = ref()
const hCaptcha = ref({
    verified: false,
    expired: false,
    token: '',
    eKey: '',
    error: '',
})
const onReset = () => {
    hCaptcha.value = {
        verified: false,
        expired: false,
        token: '',
        eKey: '',
        error: '',
    }
}
const onVerify = (tokenStr, ekey) => {
    hCaptcha.value = {
        ...hCaptcha.value,
        verified: true,
        token: tokenStr,
        eKey: ekey,
    }
}
const onExpire = () => {
    hCaptcha.value = {
        ...hCaptcha.value,
        verified: false,
        token: null,
        eKey: null,
        expired: true,
    }
    console.log('Expired')
}
const onChallengeExpire = () => {
    hCaptcha.value = {
        ...hCaptcha.value,
        verified: false,
        token: null,
        eKey: null,
        expired: true,
    }
    console.log('Challenge expired')
}
const onError = (err) => {
    hCaptcha.value = {
        ...hCaptcha.value,
        token: null,
        eKey: null,
        error: err,
    }
    console.log(`Error: ${err}`)
}
const timeZoneList = [{
    country: 'new_york',
    timeZone: " GMT+5",
    timeZoneOffSet: 5
}, {
    country: 'london',
    timeZone: "GMT+1",
    timeZoneOffSet: 1
}, {
    country: 'south_africa',
    timeZone: "GMT+2",
    timeZoneOffSet: 2
},
// {
//     country: 'cyprus',
//     timeZone: "GMT+3",
//     timeZoneOffSet: 3
// }, 
{
    country: 'dubai',
    timeZone: "GMT+4",
    timeZoneOffSet: 4
}, {
    country: 'hong_kong',
    timeZone: "GMT+8",
    timeZoneOffSet: 8
}, {
    country: 'singapore',
    timeZone: "GMT+8",
    timeZoneOffSet: 8
}, {
    country: 'australia',
    timeZone: "GMT+10",
    timeZoneOffSet: 10
}]

const requiredMessage = ({ name, args, node }) =>
    t('contact_us.required', { title: t(`${args[0]}`) })
const numberMessage = () => t('contact_us.number_only', { title: t('contact_us.tel') })
const emailMessage = () => t('contact_us.email_notValid', { title: t('contact_us.email') })
const minSelectionMessage = () => t('contact_us.checkbox_min');

const handleSubmit = async (data, node) => {
    formSubmitComplete.value = false;
    data.company === undefined || data.company === '' && delete data.company;
    const res = await sendEmail(hCaptcha.value.token, { ...data, inquiryType: String(data.inquiryType) });
    if (res) {
        node.reset();
        submitStatus.value = true;
    }
    else
        submitStatus.value = false;
    hCaptchaComponent.value.reset();
    formSubmitComplete.value = true;

}

const handleGetHCaptachaKey = async () => {
    // const data = await getHCaptachaKey()
    // if (data)
    //     hCaptachaKey.value = data?.hcaptachaKey
    hCaptachaKey.value = 'fd1c4e3a-c7c0-4dde-9ba6-1dadd7b3364f'
}

const handleCheckbox = (event) => {
    if (Object.keys(event) && Object.keys(event).length) {
        selectedOption.value = event[0];
    }
    else {
        selectedOption.value = null;
    }
};

const checkboxValidation = (option) => {
    return selectedOption.value !== option && selectedOption.value !== null;
}

const handleGetFooter = async () => {
    const data = await getFooter(locale, window.location.origin)
    if (data) {
        contactInfo.value = data?.data?.footer_menu?.contact_list
    }
}

const isEmail = (txt) => {
    return emailRegex.test(String(txt).toLowerCase())
};

const getEmailList = () => {
    return contactInfo.value?.filter((item) => {
        if (isEmail(item.translate)) {
            return item
        }
    })
};

const getPhoneNumberList = () => {
    const regions = [];
    const numbers = [];
    const contactList = [];

    const list = contactInfo.value?.filter((item) => {
        if (!isEmail(item.translate) && !item.is_title) {
            return item;
        }
    })

    list.map((item) => {
        item.is_sub_title ? regions.push(item.translate) : numbers.push(item.translate)
    })

    for (let i = 0; i < regions.length; i++) {
        contactList.push([regions[i], numbers[i]]);
    }

    return contactList;
};

onMounted(async () => {
    handleGetHCaptachaKey();
    await handleGetFooter();
})

</script>

<style lang="less">
@import '../less/mediaQuery.less';

.contact-us {

    .sub-banner {
        background: url('https://cdn.dootech.io/doo/contact_us/top_banner.png') no-repeat center top;

        @media @mobile-screen {
            background: url('https://cdn.dootech.io/doo/contact_us/top_banner_mobile.png') no-repeat center top;
        }
    }

    img {
        max-width: 100%;
    }

    .time_zone {
        h2 {
            font-size: 48px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #3e3a39;
            margin-bottom: 24px;

            @media @mobile-screen {
                font-size: 28px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #3e3a39;
                margin-bottom: 16px;
            }
        }

        p {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #3e3a39;
            margin-bottom: 80px;

            @media @mobile-screen {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #3e3a39;
                margin-bottom: 40px;
            }
        }

        .time_zone_container {
            background: url('https://cdn.dootech.io/doo/contact_us/time_zone.png') no-repeat center top;
            padding-top: 64px;
            padding-bottom: 160px;
            position: relative;
            z-index: 0;

            @media @mobile-screen {
                background: url('https://cdn.dootech.io/doo/contact_us/time_zone_mobile.png') no-repeat center top;
                padding-top: 80px;
                padding-bottom: 80px;
                background-size: cover;
            }


            .carousel {
                display: none;

                @media @mobile-screen {
                    display: block;
                }

                .carousel__pagination-button::after {
                    width: 6px;
                    height: 6px;
                }

                .carousel__pagination-button--active::after {
                    background-color: #e03323;
                }

                .carousel__prev,
                .carousel__next {
                    display: none;
                }

                .carousel__pagination {
                    margin: 0 !important;
                    padding: 0 !important;
                    margin-top: 32px !important;
                }
            }

            .time_zone_grid {
                display: grid;
                // grid-template-columns: repeat(4, minmax(0, 1fr));
                grid-template-columns: repeat(8, 1fr);
                row-gap: 80px;

                @media @mobile-screen {
                    display: none;
                }
            }

            .country {
                display: grid;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &:nth-child(1) {
                    grid-column: 1 / span 2;
                }

                &:nth-child(2) {
                    grid-column: 3 / span 2;
                }

                &:nth-child(3) {
                    grid-column: 5 / span 2;
                }

                &:nth-child(4) {
                    grid-column: 7 / span 2;
                }

                &:nth-child(5) {
                    grid-column: 2 / span 2;
                }

                &:nth-child(6) {
                    grid-column: 4 / span 2;
                }

                &:nth-child(7) {
                    grid-column: 6 / span 2;
                }

                p {
                    margin-bottom: 0;
                }

                .title {
                    font-size: 28px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    color: #3e3a39;
                    margin-bottom: 0;
                    padding-top: 24px;

                    @media @mobile-screen {
                        font-size: 20px;
                        margin-bottom: 8px;
                    }
                }

                .gmt {
                    font-family: SegoeUI;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    color: #3e3a39;
                    margin-bottom: 0;
                }
            }
        }

    }

    .form-container {
        display: flex;
        max-width: 1330px;
        margin: 160px auto;

        @media @mobile-screen {
            flex-direction: column-reverse;
            margin: 80px auto;

        }

        .detail {
            width: 33.333%;
            display: flex;
            flex-direction: column;
            background-color: #f8f8f8;
            margin-right: 80px;
            padding-top: 40px;
            padding-left: 40px;

            @media @mobile-screen {
                width: 100%;
                padding: 40px 24px;
                margin: 0;
            }

            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-bottom: 40px;

                @media @mobile-screen {
                    &:last-child {
                        padding-bottom: 0px;
                    }
                }

                img {
                    margin-right: 16px;
                    height: 16px;
                    widows: 16px;
                    margin-bottom: auto;
                    margin-top: 6px;
                }

                .tel {
                    display: grid;
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                    padding-bottom: 0;

                    @media @mobile-screen {
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                        row-gap: 16px;
                        padding-bottom: 0px;
                    }

                    p {
                        text-align: left;

                        &:nth-child(even) {
                            padding-bottom: 24px;

                            @media @mobile-screen {
                                padding-bottom: 0;
                            }
                        }
                    }

                    .country {
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #9e9e9f;
                    }
                }

            }

            .email-list {
                display: flex;
                flex-direction: column;
                padding-bottom: 40px;
                align-items: flex-start;
            }

            .email {
                display: flex;
                flex-direction: row;
                padding-bottom: 8px;
            }

            .icon-hidden {
                visibility: hidden;
            }

            .phone-list {
                display: flex;
                flex-direction: row;
                padding-bottom: 0;
            }

        }

        .form-wrapper {
            width: 66.666%;
            margin-left: 80px;

            @media @mobile-screen {
                width: 100%;
                margin: 0px;
            }
        }

        .formkit-outer {
            margin-bottom: 0;
        }

        .form {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-column: span 2 / span 2;
            row-gap: 24px;
            column-gap: 24px;

            .title {
                padding-top: 40px;
                text-align: left;
                grid-column: span 2 / span 2;
                font-size: 28px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #3e3a39;

                @media @mobile-screen {
                    font-size: 20px;
                    padding-top: 0px;
                }
            }
        }

        .button-wrapper {
            width: 100%;
        }

        .button-container {
            margin-bottom: 40px;

            @media @mobile-screen {
                margin-bottom: 80px;
            }
        }

        .submit-result {
            padding: 8px 16px;
            margin-top: 24px;
            border: 2px solid;
            text-align: left
        }

        .green {
            border-color: #60b253;
        }

        .red {
            border-color: #e03323;
        }

        button.formkit-input {
            justify-self: left;
            width: 150px;
            margin-right: auto;
            padding: 12px 36px;
            background-color: #e03323;
            border: none;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            border-radius: 0%;
            width: 100%;

            @media @mobile-screen {
                grid-column: span 2 / span 2;
            }
        }
    }

    fieldset {
        margin-inline-start: unset;
        margin-inline-end: unset;
        padding-block-start: unset;
        padding-inline-start: unset;
        padding-inline-end: unset;
        padding-block-end: unset;
        border-width: unset;
        border-style: unset;
        border-color: unset;
        border-image: unset;
        border: none;
        border-radius: unset;
    }

    legend {
        padding-inline-start: unset;
        padding-inline-end: unset;
        unicode-bidi: unset;
        border-width: unset;
        border-style: unset;
        border-color: unset;
        border-image: unset;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader {
    animation: spin .5s infinite linear;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    border: 2px solid white;
    border-bottom-color: transparent;
    border-left-color: transparent;
    margin-right: .5em;
    margin-top: auto;
    margin-bottom: auto;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.h-captcha {
    display: flex;
    align-items: start;
    padding: 8px 0;
}

.formkit-wrapper {
    max-width: none;
    width: 100%;
}

.formkit-outer {

    &.phone,
    &.email {
        @media @mobile-screen {
            grid-column: span 2 / span 2;
        }
    }

    &.companyName,
    &.enquiryType,
    &.brandType {
        grid-column: 1 / span 2;

        .formkit-fieldset {
            margin: unset;
            padding: unset;
            border-radius: unset;
            border: unset;
            max-width: 100%;

            .formkit-legend {
                text-align: left;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #3e3a39;
                text-align: left;
                display: block;
                margin: var(--fk-margin-label);
                padding: var(--fk-padding-label);
            }

            .formkit-options {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                align-items: center;
                margin: 8px 0;
                gap: 16px;

                @media @mobile-screen {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }

                .formkit-option {
                    margin: 0;
                }
            }

            .formkit-decorator {
                width: 18px;
                height: 18px;
                border-radius: unset;
            }
        }
    }

}

.companyName .formkit-label::after {
    content: '';
}

.formkit-inner {
    border-radius: 0;
    border: solid 1px #d8d8d9;
    box-shadow: none
}

[data-invalid] .formkit-inner {
    border-color: #e03323;
}

.formkit-inner:focus-within {
    box-shadow: none
}

.formkit-label {
    text-align: left;
    font-family: MicrosoftYaHei;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #3e3a39;
}

.formkit-label::after {
    content: '*';
    color: #e03323;
}

.formkit-legend::after {
    content: '*';
    color: #e03323;
}

[data-type="checkbox"] .formkit-label::after {
    content: unset;
    color: unset;
}

.formkit-input {
    border-radius: 0;
}

.formkit-message {
    text-align: left;
}

.min-height-200 {
    min-height: 200px !important;
}

[data-type="checkbox"] .formkit-input~.formkit-decorator {
    box-shadow: unset;
    outline: unset;
    border: 1px solid #d8d8d9;
    background: #FFFFFF;
}

[data-type="checkbox"] .formkit-input:checked~.formkit-decorator {
    box-shadow: unset;
    background: #e03323;
    outline: unset;

}

[data-type="checkbox"] .formkit-input:focus~.formkit-decorator {
    box-shadow: unset;
    outline: unset;
}

[data-invalid] .formkit-inner .formkit-decorator {
    border: 1px solid #e03323;
}

:focus-visible {
    outline: unset;
}

.formkit-decorator .formkit-decorator-icon polygon {
    fill: #FFFFFF;
}
</style>